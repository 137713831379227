import React from "react";
import { Footer, Navbar } from "../../components";
import Techlogo from "../../components/Techlogo";

export default function Ticketing() {
  return (
    <>
      <section className="" >
        <div className="container technolagy_header_section pb-5  mt-5 mb-5">
          <div className="row">
            <div className="col-lg-7 col-md-12 mt-5 ">
              <h3 className="vis_header_title ">
                Software Development
                <br /> Followship Program
              </h3>
              <p className="tech_header_para">
                A Software Development Fellowship Program is a structured and
                immersive learning initiative designed to accelerate the
                professional growth and skill development of individuals
                aspiring to pursue a career in software development.
              </p>
              <div className="d-flex --SoftwareDevelopment">
                <div className="tech_header_dream_job">
                  <h4 className="dream_job_title">1-5 LPA dream job CTC</h4>
                </div>
                <div className="tech_header_dream_job_center">
                  <h4 className="dream_job_title">
                    6-10 LPA super dream job CTC
                  </h4>
                </div>
                <div className="tech_header_dream_job_end">
                  <h4 className="dream_job_title">
                    Online & Off 3 Months Trial Session Free
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container mt-5">
          <div className="row justify-content-center">
            <div className="col-lg-12 ">
              <h3 className="tech_dev_expertise">
                Experiential Learning Approach for Acquiring Highly Sought-After
                <br />
                Developer Expertise
              </h3>
              <div className="d-flex justify-content-center">
                <a href="/Education" className="btn_apply">
                  Apply Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container pt-5">
          <div className="row">
            <div className="col-lg-4">
              <div className="tech_card">
                <img
                  src="/assets/Icon/professionals.png"
                  style={{ width: "40px" }}
                />
                <h4 className="tech_card_title">
                  Learn by Working like Tech Professionals
                </h4>
                <p className="tech_card_para">
                  Learn Full Stack Development, Backend Development, QA
                  Automation through an immersive project-based curriculum
                  focused on practical developer skills and real-world
                  scenarios.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="tech_card">
                <img
                  src="/assets/Icon/companies.png"
                  style={{ width: "40px" }}
                />
                <h4 className="tech_card_title">
                  Gain Work-ex on Problems Given by Real Tech Companies
                </h4>
                <p className="tech_card_para">
                  Gain the work experience of building professional software
                  products for India’s top tech companies like CRED, Playment,
                  Jumbotail, Vicara, and others.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="tech_card">
                <img
                  src="/assets/Icon/industry.png"
                  style={{ width: "40px" }}
                />
                <h4 className="tech_card_title">
                  Learn From Seasoned Industry Professionals in Real Time
                </h4>
                <p className="tech_card_para">
                  Participate in activity-based live working sessions
                  facilitated by industry mentors from companies like Microsoft,
                  Flipkart, Nilenso.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="tech_card">
                <img src="/assets/Icon/roles.png" style={{ width: "40px" }} />
                <h4 className="tech_card_title">
                  Get career support to break through into ambitious roles
                </h4>
                <p className="tech_card_para">
                  Be prepared for every interview and land exciting development
                  jobs with structured planning and personalised guidance &
                  support from Crio career coaches.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="tech_card">
                <img
                  src="/assets/Icon/recruiters.png"
                  style={{ width: "40px" }}
                />
                <h4 className="tech_card_title">
                  Build a GitHub portfolio that impresses recruiters
                </h4>
                <p className="tech_card_para">
                  Go beyond just certificates with a Crio verified project
                  portfolio on GitHub and impress any recruiter at a product
                  based company with your skills and experience.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="tech_card">
                <img src="/assets/Icon/work.png" style={{ width: "40px" }} />
                <h4 className="tech_card_title">
                  Stand out at interviews with real proof of work
                </h4>
                <p className="tech_card_para">
                  Validate your learnings and let your skills shine with a work
                  experience certificate that makes you stand out at interviews.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="integrateing_file">
        <div className="container mt-5 mb-5">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <h4 className="course_careers">
                2K Tech <br />
                Have Successfully Achieved Their Desired Careers In
              </h4>
            </div>
            <div class="col-lg-12 mb-5 pb-5 integrate_over_tools ">
              <div class="integrate_tools">
                <div class="integrate_section">
                  <h2 class="integrate">
                    Modern Work-Experience Based Learning Approach
                  </h2>
                  <p class="integrate_para">
                    Engage in real-world full-stack and backend web development
                    projects while receiving mentorship and guidance to progress
                    and excel in your career.
                  </p>
                  <a
                    href="/Education"
                    class="schedule_demo_btn_integrate"
                    style={{ marginTop: "15px", zIndex: 10 }}
                  >
                    Apply Now
                  </a>
                </div>
              </div>
              <div style={{ padding: "15px 0px" }}>
                {/* <Marquee /> */}
                <Techlogo />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
