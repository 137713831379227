import React from "react";
import { Footer, Navbar } from "../components";
import CustomerLogo from "../components/CustomerLogo";
import AnimatedCard from "@mikekorakakis/react-3d-animated-card";

export default function ProfilePage() {
  return (
    <>
      <section className="">
        <div className="container profile_pages">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <h3 className="dev_application_header ">2k Virtual World</h3>
              <p className="services_para mt-4 mb-2" style={{ color: "#fff" }}>
                2KVirtualWorld is a vibrant company in Chennai, India, known for
                its young and energetic team dedicated to solving business
                problems ethically.
              </p>
            </div>
            <div className="col-lg-6">
              <img
                src="/assets/product_header.gif"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container pt-5">
          <div className="row  pb-5 mb-5">
            <div className="col-lg-6 align-self-center order-lg-first mt-4">
              <img src="/assets/teamwork.jpg" style={{ width: "100%" }} />
            </div>
            <div className="col-lg-6 order-first align-self-center">
              <h5 className="service_sub_heading">
                Where We are 2K Virtual World?
              </h5>
              <p className="services_para mt-4 mb-2">
                2KVirtualWorld is a vibrant company in Chennai, India, known for
                its young and energetic team dedicated to solving business
                problems ethically. Our goal is to make a lasting impact by
                transforming people and organizations, with a focus on
                collaborative partnerships with our customers.
              </p>
              <p className="services_para mt-4 mb-2">
                Analysing and reporting of web data for purposes of
                understanding and optimizing web usage.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mt-5 pt-5">
              <h3 className="dev_application_header " style={{ color: "#000" }}>
                Our Services
              </h3>
            </div>
          </div>
          <div className="row  pb-5 mb-5">
            <div className="col-lg-6 align-self-center">
              <h5 className="service_sub_heading">Website Development</h5>
              <p className="services_para mt-4 mb-2">
                In today&apos;s digital landscape, websites play a pivotal role
                in defining the online identity of your business, regardless of
                its scale or industry.
              </p>
              <p className="services_para mt-4 mb-2">
                At 2K Virtualworld, we don&apos;t just offer web development and
                web Application services; we&apos;re your dedicated technology
                partner, driven by a singular vision to unleash digital
                potential through scalable, cutting-edge, secure, and
                unparalleled web development solutions.
              </p>
            </div>
            <div className="col-lg-6 align-self-center mt-4">
              <img src="/assets/web.jpg" style={{ width: "100%" }} />
            </div>
          </div>
          <div className="row  pb-5 mb-5">
            <div className="col-lg-6 align-self-center order-lg-first mt-4">
              <img
                src="/assets/mobile-app.png"
                style={{ width: "100%", borderRadius: "9px" }}
              />
            </div>
            <div className="col-lg-6 order-first align-self-center">
              <h5 className="service_sub_heading">App Development</h5>
              <p className="services_para mt-4 mb-2">
                We deliver revolutionary mobile app experiences that inspire
                user engagement & conversion.
              </p>
              <p className="services_para mt-4 mb-2">
                Get in touch to achieve app-driven business success
              </p>
              <div className="d-flex flex-row align-items-center">
                <div className="dot_service"></div>&nbsp;
                <p className="services_para pt-0 mb-0">
                  Cost-effective & Quality solution
                </p>
              </div>
              <div className="d-flex flex-row align-items-center mt-2 mb-2">
                <div className="dot_service"></div>&nbsp;
                <p className="services_para pt-0 mb-0">
                  2x Faster & agile development
                </p>
              </div>
              <div className="d-flex flex-row align-items-center">
                <div className="dot_service"></div>&nbsp;
                <p className="services_para pt-0 mb-0">
                  Flexible engagement options
                </p>
              </div>
              <div className="d-flex flex-row align-items-center mt-2 mb-2">
                <div className="dot_service"></div>&nbsp;
                <p className="services_para pt-0 mb-0">
                  Make app experience more futuristic
                </p>
              </div>
            </div>
          </div>
          <div className="row pt-5 mt-5 pb-5 mb-5">
            <div className="col-lg-6 align-self-center">
              <h5 className="service_sub_heading">Logo Design</h5>
              <p className="services_para mt-4 mb-2">
                Custom logo design made for your business
              </p>
              <p className="services_para mt-4 mb-2">
                Grow your business with a professional, custom logo designed by
                our creative experts. Because good design makes great business.
              </p>
            </div>
            <div className="col-lg-6 align-self-center mt-4">
              <img
                src="/assets/logodesign.jpg"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mt-5 pt-5">
              <h3 className="dev_application_header " style={{ color: "#000" }}>
                Our Product
              </h3>
            </div>
          </div>
          <div className="row  pb-5 mb-5">
            <div className="col-lg-6 align-self-center">
              <h5 className="service_sub_heading">Billing</h5>
              <p className="services_para mt-4 mb-2">
                Billing software simplifies the process of creating and managing
                invoices for businesses. It helps track sales, manage expenses,
                and ensures accurate and efficient billing, saving time and
                reducing errors.
              </p>
            </div>
            <div className="col-lg-6 align-self-center mt-4">
              <img
                src="/assets/product/billing_landing.png"
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div className="row  pb-5 mb-5">
            <div className="col-lg-6 align-self-center order-lg-first mt-4">
              <img src="/assets/product/Stock.png" style={{ width: "90%" }} />
            </div>
            <div className="col-lg-6 order-first align-self-center">
              <h5 className="service_sub_heading">Stock Management</h5>
              <p className="services_para mt-4 mb-2">
                Detailed item cards, batches, quick stock reports, and accurate
                inventory cost calculations.
              </p>

              <div className="d-flex flex-row align-items-center">
                <div className="dot_service"></div>&nbsp;
                <p className="services_para pt-0 mb-0">
                  Keep a record of different product variants and prices
                </p>
              </div>
              <div className="d-flex flex-row align-items-center mt-2 mb-2">
                <div className="dot_service"></div>&nbsp;
                <p className="services_para pt-0 mb-0">
                  Generate barcodes and print labels
                </p>
              </div>
              <div className="d-flex flex-row align-items-center">
                <div className="dot_service"></div>&nbsp;
                <p className="services_para pt-0 mb-0">
                  Monitor stock level using detailed reports
                </p>
              </div>
            </div>
          </div>
          <div className="row pb-5 mb-5">
            <div className="col-lg-6 align-self-center">
              <h5 className="service_sub_heading">CRM</h5>
              <p className="services_para mt-4 mb-2">
                CRM, or Customer Relationship Management, is a strategy for
                managing interactions with customers. It helps businesses build
                and maintain strong relationships by organizing and analyzing
                customer data.
              </p>
            </div>
            <div className="col-lg-6 align-self-center mt-4">
              <img
                src="/assets/product/crm_landing.png"
                style={{ width: "90%" }}
              />
            </div>
          </div>
          {/*  */}

          <div className="row  pb-5 mb-5">
            <div className="col-lg-6 align-self-center order-lg-first mt-4">
              <img
                src="/assets/product/technolagy.png"
                style={{ width: "90%" }}
              />
            </div>
            <div className="col-lg-6 order-first align-self-center">
              <h5 className="service_sub_heading">Technologies</h5>
              <p className="services_para mt-4 mb-2">
                Software technologies are rapidly evolving to meet the demands
                of an ever-changing digital landscape. Cloud computing remains a
                cornerstone, enabling scalable and flexible solutions.
                Additionally, machine learning and artificial intelligence are
                transforming how software processes and analyzes data, unlocking
                new possibilities.
              </p>
            </div>
          </div>
          <div className="row pb-5 mb-5">
            <div className="col-lg-6 align-self-center">
              <h5 className="service_sub_heading">Visitor Management</h5>
              <p className="services_para mt-4 mb-2">
                Visitor management is about keeping track of people who enter
                and leave a place. It helps enhance security and ensures a
                smooth check-in process by efficiently recording visitor
                information.
              </p>
            </div>
            <div className="col-lg-6 align-self-center mt-4">
              <img
                src="/assets/product/visit_landing.png"
                style={{ width: "90%" }}
              />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="profile_card">
                <img
                  src="./assets/biological.png"
                  style={{ width: "100%", padding: "25px 20px 0px" }}
                />
                <div className="card-content">
                  <h2
                    className="card-title"
                    style={{ color: "#000", width: "100%" }}
                  >
                    South Indian Journal of Biological Sciences
                  </h2>
                  <p
                    className="card-body"
                    style={{ color: "#000", padding: "0px" }}
                  >
                    User-friendly interface, seamless navigation, and efficient
                    features. Impressive responsiveness and timely updates.
                    Exceptional service{" "}
                  </p>
                  <div className="d-flex" style={{ gap: "3px" }}>
                    <img
                      src="/assets/startIcon/fullStart.png"
                      style={{ width: "10%" }}
                    />
                    <img
                      src="/assets/startIcon/fullStart.png"
                      style={{ width: "10%" }}
                    />
                    <img
                      src="/assets/startIcon/fullStart.png"
                      style={{ width: "10%" }}
                    />
                    <img
                      src="/assets/startIcon/fullStart.png"
                      style={{ width: "10%" }}
                    />
                    <img
                      src="/assets/startIcon/fullStart.png"
                      style={{ width: "10%" }}
                    />
                  </div>
                  <div></div>
                  <a
                    href="https://www.sijbs.com/"
                    target="_blank"
                    className="button"
                    style={{ color: "#000", padding: "6px 0px 0px" }}
                  >
                    Client Web Site
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="profile_card">
                <img
                  src="./assets/dicekart.png"
                  style={{ width: "100%", padding: "25px 20px 0px" }}
                />
                <div className="card-content">
                  <h2
                    className="card-title"
                    style={{ color: "#000", width: "100%" }}
                  >
                    Dicekart
                  </h2>
                  <p
                    className="card-body"
                    style={{ color: "#000", padding: "0px" }}
                  >
                    Effortless navigation, intuitive interface, and effective
                    features. Remarkable responsiveness and punctual updates.
                    Outstanding service!
                  </p>
                  <p
                    className="card-body"
                    style={{ color: "#000", padding: "0px" }}
                  >
                    Mobile Application Available in Play Store. Our Platinum
                    client from 2022 to 2023.{" "}
                  </p>
                  <div className="d-flex" style={{ gap: "3px" }}>
                    <img
                      src="/assets/startIcon/fullStart.png"
                      style={{ width: "10%" }}
                    />
                    <img
                      src="/assets/startIcon/fullStart.png"
                      style={{ width: "10%" }}
                    />
                    <img
                      src="/assets/startIcon/fullStart.png"
                      style={{ width: "10%" }}
                    />
                    <img
                      src="/assets/startIcon/fullStart.png"
                      style={{ width: "10%" }}
                    />
                    <img
                      src="/assets/startIcon/fullStart.png"
                      style={{ width: "10%" }}
                    />
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="profile_card">
                <img
                  src="./assets/dreamdigital.png"
                  style={{ width: "100%", padding: "25px 20px 0px" }}
                />
                <div className="card-content">
                  <h2
                    className="card-title"
                    style={{ color: "#000", width: "100%" }}
                  >
                    Root2Media Dream
                  </h2>
                  <p
                    className="card-body"
                    style={{ color: "#000", padding: "0px" }}
                  >
                    Our Silver customer from 2021 to 2022{" "}
                  </p>

                  <p
                    className="card-body"
                    style={{ color: "#000", padding: "0px" }}
                  >
                    Seamless navigation, intuitive layout, and impactful
                    features. Impressive responsiveness and timely updates.
                    <br /> We are Provide a Logo Design and WebSite
                  </p>
                  <div className="d-flex" style={{ gap: "3px" }}>
                    <img
                      src="/assets/startIcon/fullStart.png"
                      style={{ width: "10%" }}
                    />
                    <img
                      src="/assets/startIcon/fullStart.png"
                      style={{ width: "10%" }}
                    />
                    <img
                      src="/assets/startIcon/fullStart.png"
                      style={{ width: "10%" }}
                    />
                    <img
                      src="/assets/startIcon/fullStart.png"
                      style={{ width: "10%" }}
                    />
                    <img
                      src="/assets/startIcon/fullStart.png"
                      style={{ width: "10%" }}
                    />
                  </div>
                  <div></div>
                  <a
                    href="#"
                    target="_blank"
                    className="button"
                    style={{ color: "#000", padding: "6px 0px 0px" }}
                  ></a>
                </div>
              </div>
            </div>
          </div>
          {/*  */}
          <div className="row  pt-5 pb-5">
            <div className="col-lg-4">
              <div className="profile_card">
                <img
                  src="./assets/limpus.png"
                  style={{ width: "100%", padding: "25px 20px 0px" }}
                />
                <div className="card-content">
                  <h2
                    className="card-title"
                    style={{ color: "#000", width: "100%" }}
                  >
                    LIMPUS Stabilizer and Inverter
                  </h2>
                  <p
                    className="card-body"
                    style={{ color: "#000", padding: "0px" }}
                  >
                    User-friendly interface, seamless navigation, and efficient
                    features. Impressive responsiveness and timely updates.
                    Exceptional service{" "}
                  </p>
                  <p
                    className="card-body"
                    style={{ color: "#000", padding: "0px" }}
                  >
                    ur Silver customer from 2023 to still date.
                  </p>
                  <div className="d-flex" style={{ gap: "3px" }}>
                    <img
                      src="/assets/startIcon/fullStart.png"
                      style={{ width: "10%" }}
                    />
                    <img
                      src="/assets/startIcon/fullStart.png"
                      style={{ width: "10%" }}
                    />
                    <img
                      src="/assets/startIcon/fullStart.png"
                      style={{ width: "10%" }}
                    />
                    <img
                      src="/assets/startIcon/fullStart.png"
                      style={{ width: "10%" }}
                    />
                    <img
                      src="/assets/startIcon/fullStart.png"
                      style={{ width: "10%" }}
                    />
                  </div>
                  <div></div>
                  {/* <a href="https://www.sijbs.com/" target="_blank" className="button" style={{color:"#000",padding:'6px 0px 0px'}}>
      Client Web Site
      </a> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="profile_card">
                <img
                  src="./assets/panayeri.png"
                  style={{ width: "100%", padding: "11px 20px 0px" }}
                />
                <div className="card-content">
                  <h2
                    className="card-title"
                    style={{ color: "#000", width: "100%" }}
                  >
                    PANAYERI
                  </h2>
                  <p
                    className="card-body"
                    style={{ color: "#000", padding: "0px" }}
                  >
                    Seamless navigation, intuitive layout, and impactful
                    features. Impressive responsiveness and timely updates.
                    <br /> We are Provide a Logo Design and WebSite
                  </p>
                  <div className="d-flex" style={{ gap: "3px" }}>
                    <img
                      src="/assets/startIcon/fullStart.png"
                      style={{ width: "10%" }}
                    />
                    <img
                      src="/assets/startIcon/fullStart.png"
                      style={{ width: "10%" }}
                    />
                    <img
                      src="/assets/startIcon/fullStart.png"
                      style={{ width: "10%" }}
                    />
                    <img
                      src="/assets/startIcon/fullStart.png"
                      style={{ width: "10%" }}
                    />
                    <img
                      src="/assets/startIcon/halfstart.png"
                      style={{ width: "10%" }}
                    />
                  </div>
                  <div></div>
                  <a
                    href="https://www.sijbs.com/"
                    target="_blank"
                    className="button"
                    style={{ color: "#000", padding: "6px 0px 0px" }}
                  >
                    Client Web Site
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="profile_card">
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src="./assets/qdr.png"
                    style={{ width: "68%", padding: "25px 20px 0px" }}
                  />
                </div>
                <div className="card-content">
                  <h2
                    className="card-title"
                    style={{ color: "#000", width: "100%" }}
                  >
                    QDR
                  </h2>
                  <p
                    className="card-body"
                    style={{ color: "#000", padding: "0px" }}
                  >
                    Seamless navigation, intuitive layout, and impactful
                    features. Impressive responsiveness and timely updates.
                  </p>{" "}
                  <div className="d-flex" style={{ gap: "3px" }}>
                    <img
                      src="/assets/startIcon/fullStart.png"
                      style={{ width: "10%" }}
                    />
                    <img
                      src="/assets/startIcon/fullStart.png"
                      style={{ width: "10%" }}
                    />
                    <img
                      src="/assets/startIcon/fullStart.png"
                      style={{ width: "10%" }}
                    />
                    <img
                      src="/assets/startIcon/fullStart.png"
                      style={{ width: "10%" }}
                    />
                    <img
                      src="/assets/startIcon/halfstart.png"
                      style={{ width: "10%" }}
                    />
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="profile_card">
                <img
                  src="./assets/srilakshmi.png"
                  style={{ width: "100%", padding: "25px 20px 0px" }}
                />
                <div className="card-content">
                  <h2
                    className="card-title"
                    style={{ color: "#000", width: "100%" }}
                  >
                    Sri Lakshmi Build Craft
                  </h2>
                  <p
                    className="card-body"
                    style={{ color: "#000", padding: "0px", margin: "0px" }}
                  >
                    User-friendly interface, seamless navigation, and efficient
                    features. Impressive responsiveness and timely updates.
                    Exceptional service{" "}
                  </p>
                  <p
                    className="card-body"
                    style={{ color: "#000", padding: "0px", margin: "4px" }}
                  >
                    Your Silver customer from 2023 to still date.
                  </p>
                  <div className="d-flex" style={{ gap: "3px" }}>
                    <img
                      src="/assets/startIcon/fullStart.png"
                      style={{ width: "10%" }}
                    />
                    <img
                      src="/assets/startIcon/fullStart.png"
                      style={{ width: "10%" }}
                    />
                    <img
                      src="/assets/startIcon/fullStart.png"
                      style={{ width: "10%" }}
                    />
                    <img
                      src="/assets/startIcon/fullStart.png"
                      style={{ width: "10%" }}
                    />
                    <img
                      src="/assets/startIcon/fullStart.png"
                      style={{ width: "10%" }}
                    />
                  </div>
                  <div></div>
                  <a
                    href="https://srilakshmibuildcraft.com/"
                    target="_blank"
                    className="button"
                    style={{ color: "#000", padding: "6px 0px 0px" }}
                  >
                    Client Web Site
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="profile_card">
                <img
                  src="./assets/trust.png"
                  style={{ width: "68%", padding: "25px 20px 0px" }}
                />
                <div className="card-content">
                  <h2
                    className="card-title"
                    style={{ color: "#000", width: "100%" }}
                  >
                    Iyanrathai Sei Trust
                  </h2>
                  <p
                    className="card-body"
                    style={{ color: "#000", padding: "0px" }}
                  >
                    User-friendly interface, seamless navigation, and efficient
                    features. Impressive responsiveness and timely updates.
                    Exceptional service{" "}
                  </p>
                  <div className="d-flex" style={{ gap: "3px" }}>
                    <img
                      src="/assets/startIcon/fullStart.png"
                      style={{ width: "10%" }}
                    />
                    <img
                      src="/assets/startIcon/fullStart.png"
                      style={{ width: "10%" }}
                    />
                    <img
                      src="/assets/startIcon/fullStart.png"
                      style={{ width: "10%" }}
                    />
                    <img
                      src="/assets/startIcon/fullStart.png"
                      style={{ width: "10%" }}
                    />
                    <img
                      src="/assets/startIcon/fullStart.png"
                      style={{ width: "10%" }}
                    />
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="profile_card">
              <div style={{background:"#000",height:"222px",display:"flex",alignItems:"center"}}>
                <img
                  src="/assets/bllackfibrelogo.png"
                  style={{ width: "68%", padding: "25px 20px 0px" }}
                />
              </div>
                <div className="card-content">
                  <h2
                    className="card-title"
                    style={{ color: "#000", width: "100%" }}
                  >
                   Bllack Fibre
                  </h2>
                  <p
                    className="card-body"
                    style={{ color: "#000", padding: "0px" }}
                  >
                    User-friendly interface, seamless navigation, and efficient
                    features. Impressive responsiveness and timely updates.
                    Exceptional service{" "}
                  </p>
                  <div className="d-flex" style={{ gap: "3px" }}>
                    <img
                      src="/assets/startIcon/fullStart.png"
                      style={{ width: "10%" }}
                    />
                    <img
                      src="/assets/startIcon/fullStart.png"
                      style={{ width: "10%" }}
                    />
                    <img
                      src="/assets/startIcon/fullStart.png"
                      style={{ width: "10%" }}
                    />
                    <img
                      src="/assets/startIcon/fullStart.png"
                      style={{ width: "10%" }}
                    />
                    <img
                      src="/assets/startIcon/fullStart.png"
                      style={{ width: "10%" }}
                    />
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="client_logo_header">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 mt-2 mb-0 pt-3 pb-0">
              <h2 className="client_logo_title">Client Logos</h2>
              <CustomerLogo />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
