import React from "react";

// import Facebook from "../../assets/SVG/facebook";
// import Linked from "../../assets/SVG/Linked";
// import Twi from "../../assets/SVG/Twi";
import { NavLink } from "react-router-dom";
export default function Footer() {
  return (
    <section className="footer_section">
      <div className="container pt-4">
        <div className="row justify-content-between">
          <div className="col-auto mr-auto">
            <img src="/assets/logo.png" style={{ width: "25%" }} />
          </div>
          <div className="col-auto align-self-center d-flex flex-row align-items-center">
            <p className="foot_demo_para">Ready to get Started</p>
            <a href="/Contact" className="demo_btn">
              Book a Demo
            </a>
          </div>
        </div>
        <div className="row pt-3 justify-content-between">
          <div className="col-lg-3">
            <h3 className="footer_header">Services</h3>
            <div style={{ width: "250px" }}>
              <NavLink
                to="/WebApplication"
                style={{ textDecoration: "none" }}
              >
                <p className="footer_navigation">Website & Web Application</p>
              </NavLink>
              <NavLink
                to="/MobileApps"
                style={{ textDecoration: "none" }}
              >
                <p className="footer_navigation">Mobile Application</p>
              </NavLink>
              <NavLink to="/Logo" style={{ textDecoration: "none" }}>
                <p className="footer_navigation">Logo Design</p>
              </NavLink>
              <h3 className="footer_header">Social contact</h3>
              <div style={{display:"flex",gap:'9px'}}>

              <a href='' target="blank">
                <img src="/assets/footer/fb.png"/>
                              </a>
              <a href='' target="blank">
                <img src="/assets/footer/link.png"/>
                              </a>
              <a href='' target="blank">
                <img src="/assets/footer/inst.png"/>
                              </a>
              </div>
              {/* <NavLink href="/">
                <Facebook />
              </NavLink>
              <NavLink href="/">
                <Linked />
              </NavLink>
              <NavLink href="/">
                <Twi />
              </NavLink> */}
            </div>
          </div>
          <div className="col-lg-3">
            <h3 className="footer_header">Product</h3>
            <div style={{ width: "250px" }}>
              <NavLink to="/Billing" style={{ textDecoration: "none" }}>
                <p className="footer_navigation">Billing</p>
              </NavLink>
              <NavLink
                to="/StockManagement"
                style={{ textDecoration: "none" }}
              >
                <p className="footer_navigation">Stock Management</p>
              </NavLink>
              <NavLink to="/CRM" style={{ textDecoration: "none" }}>
                <p className="footer_navigation">CRM</p>
              </NavLink>

              <NavLink
                to="/VisitorManagement"
                style={{ textDecoration: "none" }}
              >
                <p className="footer_navigation">Visitor Management</p>
              </NavLink>
              {/* <NavLink
                to="/2K-Treat"
                style={{ textDecoration: "none" }}
              >
                <p className="footer_navigation">2K Treat</p>
              </NavLink> */}
            </div>
          </div>
          <div className="col-lg-3">
            <h3 className="footer_header">Company</h3>
            <div style={{ width: "250px" }}>
              <NavLink to="/Aboutus" style={{ textDecoration: "none" }}>
                <p className="footer_navigation">About us</p>
              </NavLink>
              <NavLink to="/PrivacyPolicy" style={{ textDecoration: "none" }}>
                <p className="footer_navigation">Privacy Policy</p>
              </NavLink>
              <NavLink to="/Education" style={{ textDecoration: "none" }}>
                <p className="footer_navigation">Education</p>
              </NavLink>
              {/* <NavLink
                to="/Technology"
                style={{ textDecoration: "none" }}
              >
                <p className="footer_navigation">Technology</p>
              </NavLink> */}
              {/* <NavLink to="/Career" style={{ textDecoration: "none" }}>
                <p className="footer_navigation">Career</p>
              </NavLink> */}
              <NavLink to="/Contact" style={{ textDecoration: "none" }}>
                <p className="footer_navigation">Contact Us</p>
              </NavLink>
              <NavLink to="/Terms" style={{ textDecoration: "none" }}>
                <p className="footer_navigation">Terms and Conditions</p>
              </NavLink>
            </div>
          </div>
        </div>
        {/* Web site */}
        <div className="col-lg-12 footer_bottom justify-content-center">
          <span className="copy_witeadded_bolder">© 2KVirtualWorld</span>
          <h2 className="copy_witeadded">
            &nbsp;| 2020 All Rights Reserved&nbsp;|&nbsp;
            <NavLink to="/Terms" style={{ color: "#fff",textDecoration: "none"  }}>
              Terms and Conditions
            </NavLink>
            &nbsp;|&nbsp;
            <NavLink to="/profilepage" style={{ color: "#fff",textDecoration: "none"  }}>
              Company Profile
            </NavLink>
          </h2>
        </div>
      </div>
    </section>
  );
}
