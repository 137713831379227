// import React, { useState } from "react";
// import { Footer, Navbar } from "../components";
// import { useForm } from "react-hook-form";
// import axios from "axios";
// import { send } from 'emailjs-com';

// export default function Contact() {
//   const {
//     register,
//     formState: { errors },
  
//   } = useForm();
  
// // const FormComponent = () => {
//   // const [formData, setFormData] = useState({
//   //   name: "",
//   //   email: "",
//   //   message: "",
//   // });

//   // const handleChange = (e) => {
//   //   const { name, value } = e.target;
//   //   setFormData((prevState) => ({
//   //     ...prevState,
//   //     [name]: value,
//   //   }));
//   // };

//   // const Submit = async (e) => {
//   //   e.preventDefault();
//   //   try {
//   //     // Submit data to Google Sheets
//   //     // await axios.post("https://docs.google.com/spreadsheets/d/100tGlg8bmKwVAiTuKNduFpthRrVxd4PKm2b-I7ScGDs/edit#gid=0", formData);

//   //     // Send email using Gmail API
//   //     await axios.post("", {
//   //       to: "azarudhin425@gmail.com",
//   //       subject: "New Form Submission",
//   //       body: `Name: ${formData.name}\nEmail: ${formData.email}\nMessage: ${formData.message}`,
//   //     });

//   //     // Clear form after submission
//   //     setFormData({
//   //       name: "",
//   //       email: "",
//   //       message: "",
//   //     });

//   //     alert("Form submitted successfully!");
//   //   } catch (error) {
//   //     console.error("Error submitting form:", error);
//   //     alert("An error occurred. Please try again later.");
//   //   }
//   // };
//   const [toSend, setToSend] = useState({
//     Username: '',
//     Email: '',
//     PhoneNumber: '',
//   });

//   // const onSubmit = (e) => {
//   //   e.preventDefault();
//   //   {/* --- METHOD TO SEND THE MAIL --- */}
//   // };

//   const handleChange = (e) => {
//     setToSend({ ...toSend, [e.target.name]: e.target.value });
//   };
//   const onSubmit = (e) => {
//     e.preventDefault();
//     send(
//       'SERVICE ID',
//       'TEMPLATE ID',
//       toSend,
//       'User ID'
//     )
//       .then((response) => {
//         console.log('SUCCESS!', response.status, response.text);
//       })
//       .catch((err) => {
//         console.log('FAILED...', err);
//       });
//   };
//   return (
//     <>
//       <section className="contact_heading_section">
//         <div className="container">
//           <div className="row mt-3 ">
//             <div className="col-lg-6 ">
//               <h3 className="contact_queries_heading ">
//                 Having any Queries about
//               </h3>
//               <h3 className="contact_queries_sub_heading">
//                 2K Virtual world ?
//               </h3>
//               <p className="email_support">
//                 Email Support :&nbsp;
//                 <a
//                   href="mailto:info@2kvirtualworld.com"
//                   style={{ color: "#fff" }}
//                 >
//                   info@2kvirtualworld.com
//                 </a>
//               </p>
//               <p className="support_possible">
//                 Our support team will be in touch and we will respond back as
//                 soon as possible
//               </p>
//             </div>
//             <div className="col-lg-6">
//               <form  method="POST" data-email="u.krishnagaff@gmail.com" action="https://script.google.com/macros/s/AKfycbzmco9sZTkrvZf7_hXEfSBP1HN1ZxUDk-1puFaiATSs8lZVARs/exec">
//                 <input
//                   className="form-control"
//                   type="text"
//                   placeholder="Name"
//                   name="Username"
//                   {...register("UserName")}
//                 />
//                 <input
//                   className="form-control"
//                   type="email"
//                   placeholder="Email"
//                   name="Email"
//                   {...register("Email")}
//                 />
//                 <input
//                   className="form-control"
//                   type="number"
//                   placeholder="Phone Number"
//                   name="PhoneNumber"
//                   {...register("PhoneNuber")}
//                 />

//                 <input type="submit" className="submit_btn" />
//               </form>
//             </div>
//           </div>
//         </div>
//       </section>
//       <section>
//         <div className="container mt-5 mb-5 pt-5 pb-5">
//           <div className="row">
//             <div className="col-lg-6 align-self-center">
//               <h3 className="contact_location_heading">
//                 Our&nbsp;<span className="contact_linergradent"> Location</span>
//               </h3>
//               <div className="d-flex">
//                 <h4 className="contact_location">India,Chennai</h4> &nbsp;&nbsp;
//                 <img
//                   src="/assets/flag.png"
//                   // style={{ width: "100%", height: "100%" }}
//                 />
//               </div>
//               <p className="contact_location_para">
//                 No.3, Pillayar Kovil St, near Vinayagar Temple,
//                 <br /> Kittu Nagar, Kelambakkam, TamilNadu-603103
//               </p>
//             </div>
//             <div className="col-lg-6">
//               <a
//                 href="https://www.google.com/maps/place/2k+Technologies/@12.7867556,80.2200453,15z/data=!4m6!3m5!1s0x2703576ec0ee0f27:0xace81790790e462d!8m2!3d12.7867556!4d80.2200453!16s%2Fg%2F11vpry8jvy?entry=ttu"
//                 target="_blank"
//               >
//                 <img src="/assets/location.png" style={{ width: "100%" }} />
//               </a>
//             </div>
//           </div>
//         </div>
//       </section>
//       <Footer />
//     </>
//   );
// }
import React, { useRef, useState } from "react";
import { Footer, Navbar } from "../components";
import { useForm } from "react-hook-form";
import axios from "axios";
import emailjs from "@emailjs/browser";
// import { send } from 'emailjs-com';

export default function Contact() {
  const {
    register,
    formState: { errors },
    reset
  } = useForm();
  const form = useRef();

  // const FormComponent = () => {
  // const [formData, setFormData] = useState({
  //   name: "",
  //   email: "",
  //   message: "",
  // });

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  // };

  // const Submit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     // Submit data to Google Sheets
  //     // await axios.post("https://docs.google.com/spreadsheets/d/100tGlg8bmKwVAiTuKNduFpthRrVxd4PKm2b-I7ScGDs/edit#gid=0", formData);

  //     // Send email using Gmail API
  //     await axios.post("", {
  //       to: "azarudhin425@gmail.com",
  //       subject: "New Form Submission",
  //       body: `Name: ${formData.name}\nEmail: ${formData.email}\nMessage: ${formData.message}`,
  //     });

  //     // Clear form after submission
  //     setFormData({
  //       name: "",
  //       email: "",
  //       message: "",
  //     });

  //     alert("Form submitted successfully!");
  //   } catch (error) {
  //     console.error("Error submitting form:", error);
  //     alert("An error occurred. Please try again later.");
  //   }
  // };
  const [toSend, setToSend] = useState({
    Username: "",
    Email: "",
    PhoneNumber: "",
  });

  // const onSubmit = (e) => {
  //   e.preventDefault();
  //   {/* --- METHOD TO SEND THE MAIL --- */}
  // };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    // send(
    //   'SERVICE ID',
    //   'TEMPLATE ID',
    //   toSend,
    //   'User ID'
    // )
    //   .then((response) => {
    //     console.log('SUCCESS!', response.status, response.text);
    //   })
    //   .catch((err) => {
    //     console.log('FAILED...', err);
    //   });
    emailjs
      .sendForm("service_ia5w4id", "template_fncfz37", form.current, 
       "nvT8v1tKsRCEFF4gC"
      )
      .then(
        () => {
          console.log("SUCCESS!");
          reset();
        },
        (error) => {
          console.log("FAILED...", error.text);
          reset();
        }
      );
    alert("Email Send succesfully! ");
  };
  return (
    <>
      <section >
        <div className="container contact_heading_section">
          <div className="row mt-3 ">
            <div className="col-lg-6 ">
              <h3 className="contact_queries_heading ">
                Having any Queries about
              </h3>
              <h3 className="contact_queries_sub_heading">
                2K Virtual world ?
              </h3>
              <p className="email_support">
                Email Support :&nbsp;
                <a
                  href="mailto:info@2kvirtualworld.com"
                  style={{ color: "#fff" }}
                >
                  info@2kvirtualworld.com
                </a>
              </p>
              <p className="support_possible">
                Our support team will be in touch and we will respond back as
                soon as possible
              </p>
            </div>
            <div className="col-lg-6">
              <form
              ref={form}
                method="POST"
                data-email="u.krishnagaff@gmail.com"
                action="https://script.google.com/macros/s/AKfycbzmco9sZTkrvZf7_hXEfSBP1HN1ZxUDk-1puFaiATSs8lZVARs/exec"
              onSubmit={onSubmit}
              >
                <input
                  className="form-control"
                  type="text"
                  placeholder="Name"
                  name="from_name"
                  {...register("from_name")}
                />
                <input
                  className="form-control"
                  type="email"
                  placeholder="Email"
                  name="from_email"
                  {...register("from_email")}
                />
                <input
                  className="form-control"
                  type="number"
                  placeholder="Phone Number"
                  name="from_number"
                  {...register("from_number")}
                />
   <textarea
       className="form-control"
                  type="text"
                  placeholder="Comment"
                  name="message"
          {...register('message')}
        />
                <input type="submit" className="submit_btn" />
              </form>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container mt-5 mb-5 pt-5 pb-5">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <h3 className="contact_location_heading">
                Our&nbsp;<span className="contact_linergradent"> Location</span>
              </h3>
              <div className="d-flex" style={{alignItem:"center"}}>
                <h4 className="contact_location">India,Chennai</h4> &nbsp;&nbsp;
              <div style={{width:"auto",height:"40px"}}>
                <img
                  src="/assets/flag.png"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
              </div>
              <p className="contact_location_para">
                No.3, Pillayar Kovil St, near Vinayagar Temple,
                <br /> Kittu Nagar, Kelambakkam, TamilNadu-603103
              </p>
            </div>
            <div className="col-lg-6">
              <a
                href="https://www.google.com/maps/place/2k+Technologies/@12.7867556,80.2200453,15z/data=!4m6!3m5!1s0x2703576ec0ee0f27:0xace81790790e462d!8m2!3d12.7867556!4d80.2200453!16s%2Fg%2F11vpry8jvy?entry=ttu"
                target="_blank"
              >
                <img src="/assets/location.png" style={{ width: "100%" }} />
              </a>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
