import React from "react";
import { Footer, Navbar } from "../../components";

function Logo() {
  return (
    <>
      <section className="">
        <div className="container logoApplivation mt-5 pt-5">
          <div className="row">
            <div className="col-lg-12 align-self-center">
              <h3 className="dev_application_header text-center">Logo Design</h3>
              {/* <p></p> */}
              <p className="dev_application_para text-center">
                Logo Designs Aligned with Your Brand: Crafting Engaging Logos
                that Enhance Your Business Goals.A logo may appear as a modest
                design coupled with a name, yet its impact on making your brand
                stand out amidst the competition is substantial.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container pt-5 mb-5 pt-5">
          <div className="row">
            <div className="col-lg-12 mb-3">
              <h4 className="customer_web-header">
                A Strong and Recognizable Logo is Essential for Branding:{" "}
              </h4>
              <p className="customer_web-para">
                A logo may appear as a modest design coupled with a name, yet
                its impact on making your brand stand out amidst the competition
                is substantial. Frequently, a captivating and well-thought-out
                logo has propelled businesses towards success independently. We
                excel at crafting exceptional logos that are both elegant and
                inventive.
              </p>
            </div>
            <div className="col-lg-6 mb-3">
              <h4 className="customer_web-header">
                What's Involved in Crafting the Perfect Logo:
              </h4>
              <p className="customer_web-para">
                As the premier logo design firms in Chennai, we invest
                significant design expertise to craft the ideal logo for your
                brand. Our logos are captivating and enduring. Logo design
                demands creative prowess, encompassing proficiency in language
                manipulation, harmonizing colors, selecting imagery, abstract
                ideation, font selection, and more.
              </p>
              <p className="customer_web-para">
                &#x2022; Excellent Understanding of Typography and Design
                Principles
              </p>
              <p className="customer_web-para">
                &#x2022; Generating Visuals Aligned with Business
                Characteristics
              </p>
              <p className="customer_web-para">
                &#x2022; Incorporating Company Goals into the Logo
              </p>
              <p className="customer_web-para">
                &#x2022; Original and Innovative Concepts
              </p>
              <p className="customer_web-para">
                &#x2022; Showcasing Business Distinctiveness in Projects
              </p>
            </div>
            <div className="col-lg-6">
              <img src="/assets/design_logo.jpg" style={{ width: "100%",borderRadius:'9px' }} />
            </div>
            <div className="col-lg-12 mb-3 mt-4">
              <h4 className="customer_web-header">
                Why Select 2k Virtualworld as Your Logo Design Partner
              </h4>
              <h4 className="customer_web-header">
                Crafting Your Brand's Triumph Through the Perfect Logo Design
              </h4>
              <p className="customer_web-para">
                We possess a team of skilled graphic designers who can craft a
                logo from scratch, ensuring it enhances your brand and is easily
                remembered by your target audience.
              </p>
              <p className="customer_web-para">
                &#x2022; Seasoned Design Experts
              </p>
              <p className="customer_web-para">
                &#x2022; Eye-catching and Unforgettable Logo Designs
              </p>
              <p className="customer_web-para">
                &#x2022; Masters of Logo Typeface Selection
              </p>
              <p className="customer_web-para">
                &#x2022; Variety of Design Choices to Select From
              </p>
            </div>
            <div className="col-lg-12 mb-3">
              <h4 className="customer_web-header">what we do logo design</h4>
              <p className="customer_web-para">
                A logo or brandmark serves as the visual embodiment of your
                business. Logo design encompasses the fusion of your brand
                message, imagery, and elements to craft a distinctive logo. This
                creative journey captures the core of your brand, creating a
                unique symbol. Despite its small size, this graphic wields
                significant influence in fostering recognition and forging a
                strong rapport with your audience. The process of company logo
                design entails strategic planning, creativity, and ideation.
                Designers meticulously select colors, fonts, and elements to
                elicit emotions while reflecting your brand's persona.
                Possessing a well-crafted logo design is crucial for
                establishing a robust visual identity.
              </p>
            </div>
            
          </div>
        </div>
      </section>
    
      <Footer />
    </>
  );
}

export default Logo;
