import React from "react";
import { Navbar, Footer } from "../../components";

export default function VisitorManagement() {
  return (
    <>
      <section className="">
        <div className="container vis_header_section">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="technolagy_header_title">Visitor Management</h3>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container  pt-4 mb-0 ">
          <div className="row">
            <div className="col-lg-12 my-2">
              <h3 className="visitor_management_header">Visitor Management</h3>
              <p className="customer_web-para">
                Visitor management involves the systematic control and tracking
                of individuals entering a facility or event. It includes
                processes like registration, verification, and monitoring to
                ensure security and efficiency. This system helps streamline
                check-in procedures, enhances safety by identifying visitors,
                and maintains a record of their activities within the premises.
                By employing technology and organized protocols, visitor
                management contributes to a more secure and well-managed
                environment. Overall, it aims to optimize the visitor experience
                while prioritizing safety and operational effectiveness.
              </p>
              <h3 className="privacy_policy_heading_global_get">
                Analytics and Tracking:
              </h3>
              <p className="customer_web-para">
                Unlock insights with our visitor analytics tool. Track website
                traffic effortlessly, understand user behavior, and enhance your
                online presence. Optimize engagement, boost conversions, and
                make informed decisions with our user-friendly analytics
                solution. Elevate your visitor management experience today!
              </p>
              <h3 className="privacy_policy_heading_global_get">
                User Identification:
              </h3>
              <p className="customer_web-para">
                Welcome to our visitor management system – your key to a secure
                and efficient environment! Here's a brief overview:
              </p>
            </div>

            <div className="col-lg-6 my-2 align-self-center">
              <h3 className="ticketing_heading_global">🔐 Secure Entry:</h3>
              <p className="customer_web-para">
                Ensure safety with our robust access control. Only authorized
                visitors get in.
              </p>
              <h3 className="ticketing_heading_global">
                📋 Effortless Registration:
              </h3>
              <p className="customer_web-para">
                Quick and easy sign-in for visitors – no more long queues or
                paperwork.
              </p>
              <h3 className="ticketing_heading_global">
                🤖 Smart Notifications:
              </h3>
              <p className="customer_web-para">
                Instantly alert hosts when their guests arrive. Stay informed,
                stay connected.
              </p>
              <h3 className="ticketing_heading_global">
                📅 Streamlined Scheduling:
              </h3>
              <p className="customer_web-para">
                Efficiently plan visits and meetings. Manage your calendar
                seamlessly.
              </p>
              <h3 className="ticketing_heading_global">📸 Photo Capture:</h3>
              <p className="customer_web-para">
                Enhance security with visitor photos for accurate
                identification.
              </p>
              <h3 className="ticketing_heading_global">
                📊 Analytics & Reporting:
              </h3>
              <p className="customer_web-para">
                Track visitor data, analyze trends, and generate insightful
                reports
              </p>
              <h3 className="ticketing_heading_global">
                💼 Corporate Professionalism:
              </h3>
              <p className="customer_web-para">
                Impress visitors with a modern, tech-savvy welcome experience.
              </p>
              <h3 className="ticketing_heading_global">
                🌐 Cloud Integration:
              </h3>
              <p className="customer_web-para">
                Access data anytime, anywhere. Our system syncs with the cloud.
              </p>
              <h3 className="ticketing_heading_global">🚀 Easy Integration:</h3>
              <p className="customer_web-para">
                Seamlessly connect with your existing systems. No hassle, just
                efficiency.
              </p>
              <h3 className="ticketing_heading_global">
                ✨ Experience the Future of Visitor Management.
              </h3>
            </div>

            <div className="col-lg-6 my-2">
              <img
                src="/assets/usesvisitor.png"
                style={{ width: "100%", height: "100%" ,borderRadius:'9px'}}
              />
            </div>
            <div className="col-lg-12 my-2">
              <h3 className="privacy_policy_heading_global_get mt-2">
                Conversion Tracking:
              </h3>
              <p className="customer_web-para">
                We use Conversion Tracking to understand how visitors engage
                with our content. It helps us improve your experience. Your
                privacy is important, and all data is kept confidential. Thank
                you for being a part of our community!"
              </p>
              <h3 className="privacy_policy_heading_global_get">
                Personalization:
              </h3>
              <p className="customer_web-para">
                Welcome to 2k virtual world! Streamline your visit with
                personalized visitor management. Easy sign-ins, instant
                notifications, and a warm welcome tailored just for you.
                Experience seamless hospitality from the moment you arrive. Join
                us on a personalized journey today!
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container ">
          <div className="row pb-3">
            <div className="col-lg-6 my-5 align-self-center order-lg-first mt-4">
              <img
                src="/assets/visitormanage_Privacy.png"
                style={{ width: "100%", height: "100%",borderRadius:'9px' }}
              />
            </div>
            <div className="col-lg-6 order-first align-self-center">
              <h5 className="privacy_policy_heading_global_get">
                Feedback and Surveys:
              </h5>
              <p className="services_para pt-0 mb-0">
                &#x2022; Share your thoughts! Complete our quick survey to help
                us enhance your visitor experience.
              </p>
              <p className="services_para pt-0 mb-0">
                &#x2022; We value your feedback! Take a moment to tell us about
                your visit through our brief survey.
              </p>
              <p className="services_para pt-0 mb-0">
                &#x2022; Your opinion matters! Participate in our short feedback
                survey and make a difference.
              </p>
              <p className="services_para pt-0 mb-0">
                &#x2022; Help us serve you better! Share your thoughts in our
                brief visitor feedback form.
              </p>
              <p className="services_para pt-0 mb-0">
                &#x2022; Quick survey alert! Tell us about your experience –
                your input shapes our visitor management.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 my-2">
              <h3 className="privacy_policy_heading_global_get mt-4">
                Security and Privacy:
              </h3>
              <p className="customer_web-para">
                Your safety is our priority! We employ robust security measures
                to protect visitor information.
              </p>
              <p className="customer_web-para">
                Rest easy with our privacy commitment – we never share your
                details without consent.
              </p>
              <p className="customer_web-para">
                Streamlined visitor management for a seamless experience,
                ensuring data integrity.
              </p>
              <p className="customer_web-para">
                SSL encryption safeguards your data, guaranteeing a secure visit
                every time.
              </p>
              <p className="customer_web-para">
                We value your privacy: transparent policies and secure practices
                for worry-free visits.
              </p>
            </div>
            <div className="col-lg-6 align-self-center">
            <div style={{width:"100%"}}>

              <img
                src="/assets/vismanage_Privacy.jpg"
                style={{ width: "100%", height: "100%",borderRadius:'9px' }}
              />
            </div>
            </div>
            <div className="col-lg-12">
              <h3 className="privacy_policy_heading_global_get mt-5">A/B Testing:</h3>
              <p className="customer_web-para">
                Optimize your website with A/B testing! Compare variations to
                find what engages visitors best. Test different headlines,
                images, or CTAs to enhance user experience. Make data-driven
                decisions for increased conversion rates. Unlock the potential
                of your website with A/B testing today!
              </p>
              <h3 className="privacy_policy_heading_global_get">
                Performance Optimization:
              </h3>
              <p className="customer_web-para">
                Enhance your visitor management with our optimized solutions.
                Streamline check-ins, reduce wait times, and boost efficiency.
                Enjoy a seamless experience for both guests and staff. Elevate
                security and make a lasting impression. Upgrade your system for
                peak performance today!
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
