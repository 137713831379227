import React from "react";
import { Footer, Navbar } from "../components";

function PrivacyPolicy() {
  return (
    <>
      <section >
        <div className="container privacypolicy_header_section">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="privacy_policy_heading">
                2K Virtual World
                <br />
                Privacy Policy
              </h3>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container mt-5 mb-5 pt-5 pb-5">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="privacy_policy_heading_global">
                Privacy Policy for 2K Virtual World Global Website
              </h3>
              <p className="privacy_policy_date">Last Updated: 2024</p>
              <p className="customer_web-para">
                As a general rule, this website does not collect Personal
                Information about you when you visit the site. You can generally
                visit the site without revealing Personal Information, unless
                you choose to provide such information. 2K Virtual World website
                and Mobile App is maintained and enhanced by “2K VIRTUAL
                WORLD-TEAM”.
              </p>
              <p className="privacy_policy_sub_heading">Site Visit Data</p>
              <p   className="customer_web-para">
                This website records your visit and logs the following
                information for statistical purposes -your server's address; the
                name of the top-level domain from which you access the Internet
                (for example, .gov, .com, .in, etc.); the type of browser you
                use; the date and time you access the site; the pages you have
                accessed and the documents downloaded and the previous Internet
                address from which you linked directly to the site
              </p>
              <p  className="customer_web-para">
                We will not identify users or their browsing activities, except
                when a law enforcement agency may exercise a warrant to inspect
                the service provider's logs
              </p>
              <p className="privacy_policy_sub_heading pt-3 ">Cookies</p>
              <p  className="customer_web-para">
                A cookie is a piece of software code that an internet web site
                sends to your browser when you access information at that site.
                This site uses cookies.
              </p>
              <p className="privacy_policy_sub_heading pt-3 ">
                Email Management
              </p>
              <p  className="customer_web-para">
                Your email address will only be recorded if you choose to send a
                message. It will only be used for the purpose for which you have
                provided it and will not be added to a mailing list. Your email
                address will not be used for any other purpose, and will not be
                disclosed, without your consent.
              </p>
              <p className="privacy_policy_sub_heading pt-3">
                Collection Of Personal Information
              </p>
              <p  className="customer_web-para">
                We do not automatically capture any personal information from
                you. However, the personal information that you provide on your
                profile page, such as your name, phone number, state, city etc.,
                will be stored with your consent.If you are asked for any other
                Personal Information you will be informed how it will be used if
                you choose to give it. We will retain your personal data for
                legal purposes, such as security, fraud, abuse prevention,
                financial record-keeping, academic record keeping and reporting.
                If at any time you believe the principles referred to in this
                privacy statement have not been followed, or have any other
                comments on these principles, please notify the webmaster
                through support link.
              </p>

              <p  className="customer_web-para">
                By deactivating the Personal Information in your 2K Virtual
                World account profile, the remaining information about your
                activity on the 2K Virtual World Site will no longer be
                associated with you (and can be considered equivalent to a
                deletion), except as noted below.
              </p>
              <p  className="customer_web-para">
                To request deactivation of your 2K Virtual World account and
                Personal Information, please use this account
                deactivation.Deactivation on the 2K Virtual World is a permanent
                action and cannot be reversed, so 2K Virtual World will ask you
                to complete a process that will require you to explain the need
                for deletion of the account to the team managing the deletion
                requests. Once the team approves your request, 2K Virtual World
                will Deactivate your account.
              </p>
              <p className="privacy_policy_sub_heading pt-3">Exceptions</p>
              <p  className="customer_web-para">
                &#x2022; Deletion will not apply to historical activity logs or
                archives unless and until these logs and data naturally
                "age-off" the 2K Virtual World system.
              </p>
              <p  className="customer_web-para">
                &#x2022; 2K Virtual World does not track or index every time or
                every place on the Site when or where a learner may volunteer
                Personal Information, so neither 2K Virtual World nor NCs will
                be able to help you locate or manage all such instances. 2K
                Virtual World encourages you to use discretion before
                voluntarily sharing your Personal Information on the 2K Virtual
                World Site.
              </p>
              <p  className="customer_web-para">
                &#x2022; 2K Virtual World will archive course data (in a manner
                and to the extent permitted under applicable law) to serve its
                mission to enable scientific research on cognitive science and
                education. These archives are used to produce encrypted research
                data packages for NCs (or researchers approved by NCs), and
                Personal Information may not be deleted from research data
                packages retained by NCs.
              </p>
              <p  className="customer_web-para">
                &#x2022; 2K Virtual World and each applicable NCs cannot always
                delete records of past interactions and transactions. For
                example, records relating to previous examinations must be
                retained for financial reporting, audit, and compliance reasons.
              </p>
              <p  className="customer_web-para">
                &#x2022; 2K Virtual World and each NC will retain and use
                Personal Information as necessary to comply with its legal
                obligations, resolve disputes, and as otherwise permitted by
                applicable law.
              </p>
              <p  className="customer_web-para">
                &#x2022; Learners should unsubscribe from Discussion forums &
                announcements if they wish to stop receiving course updates
              </p>
              <p className="privacy_policy_sub_heading pt-3">
                Security Of Your Personal Information
              </p>
              <p  className="customer_web-para">
                We take appropriate steps to protect the information you share
                with us. We have implemented technology and security features
                and strict policy guidelines to safeguard the privacy of your
                Personal Information from unauthorized access and improper use
                or disclosure. Data is always secured in transit and at rest.
              </p>
              <p  className="customer_web-para">
                <span className="privacy_policy_sub_heading pt-3">Note :</span>{" "}
                The use of the term 'Personal Information' in this privacy
                statement refers to any information from which your identity is
                apparent or can be reasonably ascertained.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
